import React from 'react';
import Layout from '@org/components/layout';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Seo from '@org/components/seo';

const Page = ({ data: { wpPage: data } }) => {
  // { data: { wpPage: data } }
  // const data = {};
  return (
    <Layout>
      <Seo title={data.title} />
      <div
        className="container wordpress-content mx-auto p-4"
        dangerouslySetInnerHTML={{ __html: data.content }}
      ></div>
    </Layout>
  );
};

Page.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      title
      content
    }
  }
`;

export default Page;
